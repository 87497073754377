<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">SIM Management</h1>
      <div class="flex justify-end items-end">
        <button
          class="bg-blue-600 py-3 px-6 rounded-md text-white"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center mt-10">
        <input
          type="text"
          class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name=""
          placeholder="Search Merchant name or support officer"
          id=""
          v-model="search"
        />
        <button
          class="bg-blue-600 py-3 ml-2 px-6 rounded-md text-xs text-white"
          @click="searchRecords"
        >
          Search
        </button>
        <button
          class="bg-red-600 py-3 ml-1 px-6 rounded-md text-xs text-white"
          @click="clearSearchRecords"
        >
          Clear
        </button>
      </div>
      <div class="flex bg-blue-50 p-5 mt-10">
        <div class="flex-2 inline-block time-filter mr-2">
          <p class="">Filter By:</p>
          <select
            required
            v-model="filterType"
            class="shadow h-8 appearance-none border rounded w-full px-2 text-gray-700 mt-1 focus:outline-none focus:shadow-outline"
          >
            <option value="">Select Filter Type</option>
            <option value="createDate">Creation Date</option>
            <option value="dateDeployed">Date Deployed</option>
          </select>
        </div>
        <div
          class="flex-2 inline-block time-filter"
          v-if="filterType === 'createDate'"
        >
          <p>Creation Date:</p>
          <input
            type="date"
            class="inline-block border-2 h-10 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.creationDate"
          />
        </div>
        <div
          class="flex-2 inline-block time-filter"
          v-if="filterType === 'dateDeployed'"
        >
          <p>From:</p>
          <input
            type="date"
            class="inline-block border-2 h-10 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.startDate"
          />
        </div>
        <div
          class="flex-2 inline-block time-filter"
          v-if="filterType === 'dateDeployed'"
        >
          <p>To:</p>
          <input
            type="date"
            class="inline-block border-2 h-10 px-2 rounded-sm mr-2"
            name=""
            id=""
            v-model="dateData.endDate"
          />
        </div>
        <div
          class="flex mt-5"
          v-if="filterType === 'createDate' || filterType === 'dateDeployed'"
        >
          <button
            @click="fetchDatedRecords"
            class="block uppercase shadow bg-green-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs h-10 px-3 rounded"
          >
            Filter
          </button>
          <button
            @click="clearDateFilter"
            class="block uppercase shadow bg-red-800 hover:bg-red-700 ml-2 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-3 rounded"
          >
            Reset
          </button>
          <button
            v-if="filterType === 'dateDeployed'"
            @click="downloadDatedCSV"
            class="block uppercase shadow bg-blue-800 hover:bg-blue-700 ml-2 focus:shadow-outline focus:outline-none text-white h-10 text-xs px-3 rounded"
          >
            Download
          </button>
        </div>
      </div>
      <div class="mt-14">
        <div class="mt-5" style="max-height: 600px; overflow: auto">
          <table class="shadow-lg bg-white w-full relative border-collapse">
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="12"
                style="background: #dee6f2"
              >
                Merchant Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Support Officer
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Merchant Name
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Terminal Serial No
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Store Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                OLD SIM ISP
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Old SIM Number
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                New SIM Number
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                New SIM ISP
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Best TELCO
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Test Transaction Status
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Form Submission Date
              </th>
            </tr>
            <tr class="text-xs" v-for="(account, i) in filterAccounts" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.supportOfficerName || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.merchantName || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.serialNumber || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.businessName || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.oldSimISP || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.oldSimNumber || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.newSimNumber || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.newSimISP || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.bestTelco || "N/A" }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.testTransaction }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(account.createdAt).format("DD MMM YYYY - h:mm:ss a")
                }}
              </td>
            </tr>
          </table>
        </div>
        <div class="mt-3">
          <button
            @click="prevPage(currentPage)"
            :disabled="currentPage === 1 ? isDisabled : !isDisabled"
            class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
            :class="
              currentPage === 1
                ? 'bg-blue-50 text-black'
                : 'bg-blue-900 text-white'
            "
          >
            &laquo; Prev
          </button>

          <button
            @click="nextPage(currentPage)"
            class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
            v-if="filterAccounts.length > 99"
          >
            Next &raquo;
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { json2excel } from "js2excel";
import { GET_USER_BUSINESS_DATA } from "../browser-db-config/localStorage";
import authorizedUsers from "../config/authUsers";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      selected: [],
      selectedWhitelist: [],
      currentPage: 1,
      isDisabled: true,
      dateData: null,
      filterType: "",
      filterActive: false,
      searchActive: false,
    };
  },
  computed: {
    ...mapGetters(["GET_SIM_MANAGEMENT"]),
    filterAccounts() {
      //let y = this.GET_DOCUMENTS.data.filter((b) => b.status === x);
      if (this.GET_SIM_MANAGEMENT.data) {
        return this.GET_SIM_MANAGEMENT.data.filter(
          (account) =>
            account.supportOfficerName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            account.merchantName
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      } else {
        return [];
      }

      // this.documents = filtered;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {},
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      creationDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchRecords();
  },
  methods: {
    async fetchRecords() {
      this.isLoading = true;
      let response = await this.$store.dispatch("FETCH_SIM_MANAGEMENT");
      this.accounts = response.data;
      this.isLoading = false;
      if (response.data) {
      }
    },
    async fetchDatedRecords() {
      const filterType = this.filterType;
      this.currentPage = 1;
      if (this.filterType !== "") {
        this.filterActive = true;
        if (
          this.dateData.startDate > this.dateData.endDate ||
          (this.dateData.startDate === this.dateData.endDate &&
            this.filterType !== "createDate")
        ) {
          alert(
            "Sorry, you entered an invalid date pair. Please check it and try again."
          );
        } else if (
          this.filterType === "createDate" &&
          this.dateData.createDate === ""
        ) {
          alert("You cannot submit and empty date field");
        } else {
          try {
            this.isLoading = true;
            let payload = { date: this.dateData, filterType };
            let res = await this.$store.dispatch(
              "FETCH_DATED_PAGINATE_SIM_MANAGEMENT",
              payload
            );
            this.isLoading = false;
            if (res.status) {
              if (res.data === null) {
                this.isLoading = false;
                alert(
                  res.error ||
                    res.message ||
                    "Error: Please check network or contact admin."
                );
                this.fetchRecords();
              }
            } else {
              alert(
                res.error ||
                  res.message ||
                  "Error: Please check network or contact admin."
              );
            }
          } catch (error) {}
        }
      }
    },
    clearDateFilter() {
      this.currentPage = 1;
      this.filterType = "";
      this.fetchRecords();
    },
    async downloadDatedCSV() {
      if (this.filterType !== "") {
        this.filterActive = true;
        if (
          this.dateData.startDate > this.dateData.endDate ||
          (this.dateData.startDate === this.dateData.endDate &&
            this.filterType !== "createDate")
        ) {
          alert(
            "Sorry, you entered an invalid date pair. Please check it and try again."
          );
        } else if (
          this.filterType === "createDate" &&
          this.dateData.createDate === ""
        ) {
          alert("You cannot submit and empty date field");
        } else {
          this.isLoading = true;
          try {
            let res = await this.$store.dispatch(
              "DOWNLOAD_DATED_CSV_SIM_MANAGEMENT",
              this.dateData
            );

            this.isLoading = false;
            if (res.status == true) {
              alert(res.message);
              // window.open(res.data, "_blank");
              this.fetchRecords();
            } else {
              alert(
                res.error ||
                  res.message ||
                  "Error: Please check network or contact admin."
              );
            }
          } catch (error) {}
        }
      }
    },

    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let filterActive = this.filterActive;
      let filterType = this.filterType;
      let date = this.dateData;
      let searchActive = this.searchActive;
      let search = this.search;
      let payload = {
        page: currPage - 1,
        filterActive,
        filterType,
        date,
        searchActive,
        search,
      };
      let res = await this.$store.dispatch("PAGINATE_SIM_MANAGEMENT", payload);
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
        if (res.data === null) {
          alert(res.message);
          this.fetchRecords();
        }
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let filterActive = this.filterActive;
      let filterType = this.filterType;
      let searchActive = this.searchActive;
      let search = this.search;
      let payload = {
        page: currPage + 1,
        filterActive,
        filterType,
        date: this.dateData,
        searchActive,
        search,
      };
      let res = await this.$store.dispatch("PAGINATE_SIM_MANAGEMENT", payload);
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
        if (res.data === null) {
          alert(res.message);
          this.fetchRecords();
        }
      }
    },
    async downloadCSV() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("DOWNLOAD_CSV_SIM_MANAGEMENT");
        this.isLoading = false;

        if (res.status) {
          // window.open(res.data, "_blank");
          alert(res.message);
          this.fetchRecords();
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
    async searchRecords() {
      this.isLoading = true;
      this.searchActive = true;
      this.currentPage = 1;
      try {
        let res = await this.$store.dispatch(
          "SEARCH_SIM_MANAGEMENT",
          this.search
        );

        this.isLoading = false;
        if (res.status) {
          this.isLoading = false;
          if (res.data === null) {
            alert(res.message);
            this.search = "";
            this.fetchRecords();
          }
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
    async clearSearchRecords() {
      if (this.search !== "") {
        this.search = "";
        this.fetchRecords();
        this.searchActive = false;
        this.currentPage = 1;
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
